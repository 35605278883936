.product-list-details-label {
    max-width: 223px !important;
    min-width: 223px !important;
}

.product-list-details-value {
    text-align: left;
}

.product-list-details-row {
    border-bottom: gray 1px solid;
}


.product-list-details-row-half {
    width: 60%;
    border-bottom: gray 1px solid;
}