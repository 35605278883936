.taxonomies-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.taxonomies-item {
  border: 1px solid #D4CFCF;
  border-radius: 4px;
  padding: 6px 12px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.taxonomies-counter-row {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.taxonomies-row {
  width: 100%;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
}

.taxonomies-input {
  width: 100%;
  margin: 0 auto;  
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
}
