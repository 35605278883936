@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.btn-primary:disabled {
  border-color: grey;
  background-color: grey;
}

.loader-wrapper {
  position: relative;
}

.logo-wrap {
  display: inline-block;
  max-width: 300px;
}

.logo-wrap img {
  max-width: 100%;
}

.navbar.fixed-top + .container {
  margin-top: 60px;
}

.container a:not([class^='btn']),
a.btn-link,
button.btn-link {
  color: #447ab2;
}

a.btn-primary,
button.btn-primary {
  border-color: #447ab2;
  background-color: #447ab2;
}

.editable {
  cursor: pointer;
  border-bottom: 1px dashed gray;
}

input.editable {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.group-header {
  margin: 2rem 0 0.8rem 0;
}

.group-title {
  font-size: 1.5rem;
}

.group-subtitle {
  font-size: 1.3rem;
}

.group-position {
  margin-left: 10px;
  text-align: center;
}

.group-parent_title {
  font-size: 2rem;
}

.quote-view-wrap {
  max-width: 1440px;
  margin: 0 auto;
}

.remove-group-link {
  float: right;
}

/* Line breaks for pdf */
.list-group {
  break-inside: auto;
}

.product-view,
.quote-view-totals {
  break-inside: avoid;
}

.product-view-notes textarea {
  align-items: center;
  position: relative;
}

.product-view-notes textarea::placeholder {
  text-align: center;
  color: #d9d9d9;
  font-size: 0.8rem;
  font-style: italic;
}

.add-to-quote-checkbox {
  left: -10px;
  top: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 3;
}

.overlay img.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
}

.product-list-item-header {
  display: flex;
  align-items: center;
}

/* .product-list-title {
  margin-left: 15px;
  font-size: 1.3em;
} */

/* Up and down buttons */
.up-and-down-buttons-block {
  display: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.product:hover .up-and-down-buttons-block {
  display: flex;
  flex-direction: column;
}

.up-and-down-buttons-block .btn {
  font-size: 2rem;
  line-height: 1;
}

/* prev/next single product nav */
.single-product-nav-row {
  display: flex;
  margin-bottom: 1rem;
}

.single-product-nav.next-product-link {
  margin-left: auto;
}

@media all and (max-width: 768px) {
  .add-to-quote-checkbox {
    position: static !important;
    transform: scale(2);
    margin-bottom: 1rem;
    margin-left: 0 !important;
  }
  .product-list-title {
    margin-top: 3px;
  }
  .delete-product-button {
    float: right;
  }
}

.component-form-row {
  margin-bottom: 10px;
}

.product-form-costs label {
  width: 100%;
}

.product-form-costs .cost-price label {
  width: initial !important;
}

li.small-text {
  font-size: 0.8rem;
  padding-bottom: 8px;
}

.quote-product-qty-label {
  padding-top: 7px;
}

.quote-product-qty-input {
  width: 80% !important;
}

.dropzone {
  display: flex;
  justify-content: center;
  width: auto;
  margin-bottom: 1rem;
  height: 266px;
  border: 2px dashed #666;
  border-radius: 5px;
}

.dropzone.active {
  border: 2px solid #6c6;
  background-color: '#eee';
}

.dropzone.reject {
  border: 2px solid #c66;
  background-color: '#eee';
}

.dropzone .drag-drop-caption {
  align-self: center;
  font-size: 1.5rem;
  text-align: center;
}

.thumbs-container {
  align-self: center;
}

.thumb {
  display: inline-flex;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  margin: 8px auto;
  width: 180px;
  height: 180px;
  padding: 4px;
  box-sizing: border-box;
}

.large-layout .total-body {
  font-size: 1rem;
  padding: 10px 35px !important;
  width: 98% !important;
  margin-left: 11px;
}

.large-layout .total-header {
  margin-left: 11px;
}

.small-layout .total-body {
  font-size: 1.1rem;
  padding: 10px 50px !important;
}

.thumb-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.thumb-inner img {
  display: block;
  width: auto;
  height: 100%;
}

.list-group-item.product {
  padding-bottom: 0;
}

.large-layout-container .terms-and-conditions-container {
  margin-left: 11px;
}

.large-layout-container .details-header ul.list-unstyled {
  line-height: 17px;
}

.large-view-list-container {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  margin: 15px;
  min-height: 400px;
}

@media only screen and (max-width: 575px) {
  .large-view-list-container {
    margin: 0px;
  }
}

.large-view .details-header {
  color: #c0c0c0;
}

.large-layout-container .large-view-list-container h5 {
  font-size: 1.5rem;
}

@media only print, screen and (min-width: 576px) {
  .large-view .full-card {
    display: flex;
  }
}

@media only print, screen and (min-width: 576px) {
  .large-view .details-container {
    display: flex;
  }
}

@media only screen and (max-width: 575px) {
  .large-view .details-container {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .large-view .details-header {
    margin-top: 10px;
  }
}

.large-view .product-heading {
  padding: 0 10px;
}

.large-view .full-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.large-view .details-container div {
  text-align: center;
}

.product .product-title {
  font-size: 1.5rem;
  padding-right: 60px;
}

.product .edit-delete-product-wrap {
  position: absolute;
  top: 0;
  right: 10px;
}

.greyed-out-large {
  background: #f2f2f2;
}

.product .edit-delete-product-wrap button {
  padding: 0 5px;
}

.product .product-title button {
  padding: 0 0.3rem;
  font-size: 1.2rem;
  line-height: 1;
}

.col.product-image-wrap {
  padding: 0;
  text-align: center;
}

.product-view-image-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-error {
  display: block;
  font-size: 12px;
  color: red;
  font-weight: bold;
}

.product-image-wrap img,
.product-view-image-wrap img {
  max-width: 100%;
  /* max-height: 175px; */
  margin-top: 1px;
}

.product-view-image-wrap-small img {
  max-height: 175px;
}

.quote-view-wrap .product-group-view .options-shown .product-view-image-wrap {
  position: relative;
  padding-left: 32px;
}

/* edit quote */

button.quote-edit-product-button {
  padding-left: 0;
  padding-right: 0;
  font-size: 1.25rem;
}

.quote-edit-information-wrap li {
  margin-top: 0.5rem;
}

.quote-edit-information-wrap a {
  text-decoration: underline;
}

.quote-edit-information-wrap a .fa-external-link-alt {
  margin-left: 5px;
}

.quote-features li {
  position: relative;
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

.quote-features li .label,
.quote-features li .value {
  width: 50%;
}

.finish-swatch-image-wrap {
  display: inline-block;
  max-width: 60px;
  max-height: 60px;
  overflow: hidden;
}

.finish-swatch-image-wrap img {
  max-width: 100%;
}

.finishes-list {
  padding: 0;
}

.product-view-information-wrap .finishes-list li,
.upload-finishes-wrap .finishes-list li {
  padding: 5px;
  list-style-type: none;
  text-align: center;
  text-decoration: none;
}

.finishes-list li .finish-title {
  display: block;
  font-size: 0.875rem;
  white-space: nowrap;
}

.upload-finishes-wrap button {
  font-size: 1.875rem;
}

.component-form-row.row > div {
  padding: 0 2px 0 2px !important;
  display: flex;
}

.components-save-button-container {
  display: flex;
  justify-content: end;
}

.components-container textarea {
  width: 600px;
  height: 80px;
}

.components-container .textarea-container {
  padding: 0 2px 0 2px !important;
}

.components-row-currency-select > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.component-form-row.row > div > input {
  width: 100% !important;
}

/* Quote view */
.welcome-modal {
  white-space: pre-line;
}

.modal-large {
  min-width: 1200px;
}

.captions,
.product-view .greyed-out .row.captions {
  background: #bfbfbf;
  color: #ffffff;
  padding: 0;
}

.row.captions {
  margin-left: 0;
  margin-right: 0;
}

.large-layout-container .quote-details {
  margin-left: 5px;
}

.search-input input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.product-group-view {
  color: #595959;
  font-size: 0.875rem;
}

.product-group-view-header {
  margin: 1rem 0 0.5rem 0;
}

.large-layout-container .product-group-view-header {
  margin-left: 17px;
}

.product-group-view-parent-title {
  margin-bottom: 0;
}

.product-group-view-title {
  margin: 0.5rem 0 0.75rem 0;
}

.product-group-view-subtitle {
  font-size: 0.875rem;
}

.product-view-image-wrap {
  position: relative;
}

.product-group-view .my_option_wrapper {
  display: flex;
  justify-content: center;
  width: 75px;
  min-width: 75px;
  max-width: 75px;
}

.product-group-view .option {
  position: absolute;
  top: 50%;
  left: 15px;
  display: block;
  color: #808080;
  text-transform: uppercase;
  font-size: 1.125rem;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.product-group-view .my_option {
  display: block;
  color: #808080;
  text-transform: uppercase;
  font-size: 1.125rem;
  transform: rotate(-90deg);
  /* transform-origin: top left; */
}

.product-view-description-wrap,
.product-view-information-wrap,
.product-view-quantity-wrap,
.product-view-sell-price-wrap,
.product-view-total-wrap {
  display: flex;
}

.product-view-description-wrap .va-middle,
.product-view-information-wrap .va-middle,
.product-view-quantity-wrap .va-middle,
.product-view-sell-price-wrap .va-middle,
.product-view-total-wrap .va-middle {
  display: inline-block;
  align-self: center;
}

.product-view-description-wrap li:before {
  content: '> ';
}

.product-view-information-wrap li {
  list-style-type: none;
  font-size: 1.1rem;
  text-decoration: underline;
}

.product-view-information-wrap li a {
  display: inline-block;
  margin-bottom: 1rem;
  color: #595959;
  font-size: 1rem;
}

.product-view-information-wrap li a .fa-external-link-alt {
  margin-left: 5px;
}

.product-view .list-group-item > .row {
  min-height: 150px;
  margin: 0;
  min-height: 150px;
}

.product-view .greyed-out .row {
  background: #f2f2f2;
  /* padding: 0.75rem 1.25rem; */
}

.product-view.large-view .greyed-out .row {
  background: #f2f2f2;
  padding: 0.75rem 1.25rem;
}

.product-view .list-group-item {
  padding: 0;
}

.lead-time {
  font-size: 1rem;
}

.total-wrap {
  color: #595959;
}

.total-wrap header h3 {
  font-size: 1rem;
  text-transform: uppercase;
}

.total-wrap .total-body {
  width: 100%;
  border: 1px solid #595959;
}

.tcs-list {
  padding-left: 0;
  list-style-position: inside;
}

.quote-view-wrap {
  font-size: 0.8rem;
}

.product-group-view-title {
  font-size: 1.25rem;
}

/* remove margin on bullets */
.product-view-description-wrap .list-unstyled {
  margin-bottom: 0;
}

.product-view-description-wrap,
.product-view-quantity-wrap,
.product-view-sell-price-wrap,
.product-view-total-wrap {
  font-size: 0.8rem;
}

.large-layout-container .product-view-description-wrap,
.large-layout-container .product-view-quantity-wrap,
.large-layout-container .product-view-sell-price-wrap,
.large-layout-container .product-view-total-wrap {
  font-size: 1rem;
}

.product-view-quantity-wrap .va-middle,
.sell-price.va-middle,
.product-view-total-wrap .va-middle {
  display: block;
  width: 100%;
  font-size: 1.1rem;
}

.product-view-description-wrap h5,
.product-view-image-wrap .option {
  font-size: 1.1rem;
}

.prices .sell-price,
.prices .total {
  display: inline-block;
}

/* Custom quote view product columns */
.image-column,
.description-column,
.information-column,
.qty-column,
.price-column,
.total-column {
  padding: 0 5px;
}

.image-column {
  width: 25%;
}

.description-column {
  width: 32%;
}

.large-view .description-column {
  width: 100% !important;
}

.large-view .product-view-notes textarea {
  align-items: center;
  position: relative;
  margin-top: 15px;
}

.large-view .information-column {
  width: 100% !important;
}

.large-view .image-column-large .img-inner-wrap {
  height: 340px !important;
  width: 300px !important;
  text-align: center !important;
  line-height: 340px !important;
  padding-left: 23px !important;
}

.large-view .description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 575px) {
  .large-view .description-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.large-view .description-content {
  height: 80%;
}

.large-view .image-column-large .img-inner-wrap img {
  max-height: 99% !important;
  max-width: 100% !important;
  vertical-align: middle !important;
}

.large-view .finish-title {
  white-space: initial !important;
  word-break: break-word;
  display: inline-block !important;
}

.information-column {
  width: 14%;
}

.qty-column {
  width: 7%;
}

.price-column {
  width: 10%;
}

.total-column {
  width: 12%;
}

.img-inner-wrap,
.finishes-inner-wrap {
  position: relative;
}

.img-inner-wrap {
  max-width: 200px;
  margin: 0 auto;
}

.image-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url();
}

.image-loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
}

.fs-12 {
  font-size: 12px;
}

.quote-logo img {
  max-width: 100%;
}

.upload-quote-logo-title {
  margin: 1rem 0;
}

/* this is so that client notes caption breaks in two lines approx as soon as our price
and both caption columns have the same height*/
.captions .restrict-width {
  display: block;
  max-width: 43.8%;
  margin: 0 auto;
}

@media print {
  .not-printable {
    display: none;
  }
  a {
    text-decoration: none !important;
  }
  .col-md-10.product-view-columns,
  .col-md-10.captions-columns,
  .col-md-10.product-view-total {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-12.product-view-columns,
  .col-md-12.captions-columns,
  .col-md-12.product-view-total {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* mobile styles for client quote view */
.product-view-quantity-wrap .captions,
.product-view-sell-price-wrap .captions,
.product-view-total-wrap .captions,
.product-view-notes .captions {
  display: none;
}

@media all and (max-width: 768px) {
  .product-view .greyed-out .row {
    padding: 0;
  }
  .product-view-quantity-wrap,
  .product-view-sell-price-wrap,
  .product-view-total-wrap,
  .product-view-quantity-wrap .captions,
  .product-view-sell-price-wrap .captions,
  .product-view-total-wrap .captions,
  .product-view-notes .captions {
    display: block;
  }
  .product-view-description-wrap,
  .product-view-information-wrap {
    margin: 1rem 0;
  }

  .product-view-notes {
    margin-bottom: 3rem;
  }

  .product-view-information-wrap li a {
    margin-bottom: 0;
  }

  .image-column,
  .description-column,
  .information-column,
  .qty-column,
  .price-column,
  .total-column {
    width: 100%;
  }
  .product-list-item-header {
    display: block;
  }
  .product-list-item-header .product-list-title {
    margin-left: 0;
  }

  .caption-client-notes {
    padding-left: 5px;
  }
}

.image-column-large {
  width: 100%;
}

.image-column-large img {
  /* max-height: 275px; */
}

.image-column-large .img-inner-wrap {
  max-width: 300px;
}

.components-button-container {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 770px) {
  .components-button-container {
    margin-bottom: 16px;
  }
}

.components-count {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
}

.components-count-inner {
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1000px;
}

.large-view .finishes-list {
  flex-direction: column;
  align-items: center !important;
}

.large-view .finishes-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.large-view .information-column.product-view-information-wrap {
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .large-view .information-column.product-view-information-wrap {
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .large-view .finishes-list {
    flex-direction: row;
  }
}

@media only screen and (max-width: 575px) {
  .large-view .finishes-container {
    padding-right: 20px;
    flex-direction: row;
  }
}

.text-disabled {
  text-decoration: line-through;
  color: #989898 !important;
}

.my-modal-very-large {
  min-width: 1700px;
}

@media only screen and (max-width: 1725px) {
  .my-modal-very-large {
    min-width: 95%;
  }
}

.components-input-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.quote-edit-note-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-size: 14px;
  width: 100%;
  background-color: #e9ecef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.quote-edit-note-textarea {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-width: 0px;
}

.product-list-supplier-title {
  font-weight: bold;
  color: #929292;
  margin-top: -4px;
  font-size: 14px;
}

.my-zoomed-image img {
  height: auto;
  max-height: 97.5%;
  margin: 0 auto;
  border-radius: 0.25rem;
}
